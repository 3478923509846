
import IObjectKeys from "./IObjectKeys";
import axios from 'axios';

export default class api {
    



    
    public static async get (url: string, hdrs?: IObjectKeys, opts:IObjectKeys={"JSON":true}) : Promise <Buffer|IObjectKeys|IObjectKeys[]|undefined> {
        return new Promise((resolve, rej) => {
            const options={
                headers: hdrs,
            };
            axios.get(url, options).then ( (res) => {
                //console.log('statusCode:', res.statusCode);
                console.log('data', res.data);
                let data=res.data;
                if(opts?.JSON) {
                    try {
                        if (typeof data=="string") {
                            data=data.replace(/\t/g,' ')
                            const jsobj=JSON.parse(data)
                            resolve(jsobj)
                        }
                        else {
                            resolve(data)
                        }
                    }
                    catch (ex){
                        console.log(`ERROR PARSING DATA `,res.data)
                    }
                }
                else  resolve(data)
            }).catch((error)=> {
                resolve(undefined)
            })
        });    
    }

    public static async post(url: string, hdrs: IObjectKeys, post_data: any) : Promise <any>{
        const header = hdrs || {}
        header["Content-Type"] = "application/json"
        const poststr = JSON.stringify(post_data);
        return new Promise( (resolve, rej) => {
            const options = {
                headers: hdrs
            };
            axios.post(url, poststr, options).then ((res) => {
                const data=res.data
                try {
                    const jsobj=JSON.parse(data)
                    resolve(jsobj)
                }
                catch (ex){
                    resolve(data)
                }                
            });
        });
    }




 
}
