import { IonAvatar,IonButton,IonButtons, IonContent, IonHeader, IonSpinner, IonPage, IonTitle, IonToolbar, useIonViewWillEnter, IonCard, IonCheckbox, IonGrid, IonRow, IonCol, IonFooter, IonItem, IonIcon } from '@ionic/react';
import useStore from "../modules/userState";
import { useEffect, useRef, useState } from 'react';
import './New.css';
import IObjectKeys from '../modules/IObjectKeys';
import { refreshCircle } from 'ionicons/icons';
import Photos from '../modules/photos';


const Declined: React.FC = () => {
 
  const [Declines, setDeclines]=useState<undefined|IObjectKeys[]>([])
  const [itemsReady, setItemsReady]=useState(false)
  const [busy, setBusy]=useState(false)
  const {setEmail, setAuthorization, setKey, email, authorization, key}=useStore();
  
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  
  useEffect(()=>{
  //  const getDeclines=async()=>{
 //   }
    getDeclines();
    //setDeclines(result)
    console.log('Declines', Declines)
  },[]
  ) 

  useIonViewWillEnter(()=> {
    getDeclines();
  })

  const getDeclines=async()=> {
    setDeclines([])
    setBusy(true)
    const result=await Photos.getDeclined(authorization);

    setDeclines(result)
    setBusy(false)
  }

 
 function doRefresh() {
    setBusy(true)
    getDeclines();
    setBusy(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Declined Photos</IonTitle>
          <IonButton slot='end' onClick={()=>{doRefresh()}} >
            <IonIcon slot='icon-only' icon={refreshCircle} ></IonIcon>
          </IonButton>  
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Print Queue</IonTitle>
          </IonToolbar>
        </IonHeader>
        { busy?<div id="pluswrap">
            <div className="plus">
            <IonSpinner></IonSpinner>
            </div>
          </div>:""}
           {Declines && Declines.map(decline=>(
           <IonItem className='q'>
              {decline.name} ({decline.npn}) <IonAvatar slot="end">
                          <img src={decline.link}></img>
                        </IonAvatar>
           </IonItem>))}
          { Declines?.length==0 && !busy? <IonItem>There are not current declined photos.</IonItem>:""}
      </IonContent>
      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default Declined
