import { Preferences } from "@capacitor/preferences"
import api from "./api"
import IObjectKeys from "./IObjectKeys"


export interface IBondsman {
   "agent" : {
        "residency": string,
        "licenseTypeCode": string,
        "licenseExpirationDate": string,
        "loas": string,
        "fein": string,
        "dbaName": string,
        "npn": string,
        "designatedHomeState": string,
        "licenseType": string,
        "licenseEffectiveDate": string,
        "name": string,
        "licenseNumber": number
        "businessPhone":string,
        "businessAddress": string
      },                                         
    "licenses":         {
        "licenseId": number,
        "licenseType": string
        "licenseTypeCode": string
        "licenseNumber": number,
        "licenseStatus": string,
        "statusDate": string,
        "firstActiveDate": string,
        "effectiveDate": string,
        "expirationDate": string,
        "stateLicenseNo": string|null,
        "shopExchangeAuth": string,
        "individualExchangeAuth": string,
        "federalExchangeFlag":  string|null,
        "loaLevelExpireFlag": string|null,
        "desigHomeState": string|null
    }[],
    "emails":     {
      "emailAddress": string,
      "emailType": string,
      "emailTypeCode": string,
      "csvExport": null|string
    }[]
    "photo_url": string
}

export interface IBondsmanAgent {
  "residency": string,
  "licenseTypeCode": string,
  "licenseExpirationDate": string,
  "loas": string,
  "fein": string,
  "dbaName": string,
  "npn": string,
  "designatedHomeState": string,
  "licenseType": string,
  "licenseEffectiveDate": string,
  "name": string,
  "licenseNumber": number
  "businessPhone":string,
  "businessAddress": string
}


export default class Bondsman {
    public static HOST_URL=process.env.REACT_APP_API_HOST_URL;

    public static getHeaders(authorization: string): IObjectKeys {
                return { "Authorization": authorization}
    }

    public static async lookup(npn: string) : Promise <IBondsman> {
        const bondsman=(await api.get(`${this.HOST_URL}npn_lookup/${npn}`,{}, {JSON:true})) as IBondsman
        return bondsman
    }

    public static async getAgentInfo(npn: string, force_refresh=false) : Promise <any> {
      if (!force_refresh) {    //force_refresh - Don't use the cached values for agent.
        let bmstr=(await Preferences.get({"key":npn}))
        if (bmstr.value && bmstr.value!="undefined") {
          console.log('bmstr', bmstr.value)
          try {
            const ret=JSON.parse(bmstr.value)
            console.log(ret.name)
            return ret
          }
          catch(ex) {
            console.log(ex)

          }
        }
      }
      let HOST=this.HOST_URL
      if (this.HOST_URL!.indexOf("localhost")>0) { //localhost does not include the agent_info url.. so switch to public url if specified
          HOST="https://our.ncdoi.com/bailbond/"
      }
      const bondsman=await api.get(`${HOST}agent_info/${npn}`,{}, {JSON:true})
      const bbmstr=JSON.stringify(bondsman) 
      await Preferences.set({"key": npn, "value": bbmstr})           
      return bondsman
      //}
    }

    public static async getAccessCode(npn: string, authorization: string) : Promise <string> {
        let HOST=this.HOST_URL
        let headers=this.getHeaders(authorization)
        const result: IObjectKeys=(await api.get(`${HOST}bondsman_access_code?npn=${npn}`,headers,{JSON: true})) as IObjectKeys      
        return result.code
    }
  }