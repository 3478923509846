import { IonAvatar,IonButton,IonButtons, IonContent, IonHeader, IonSpinner, IonPage, IonTitle, IonToolbar, useIonViewWillEnter, IonCard, IonCheckbox, IonGrid, IonRow, IonCol, IonFooter, IonItem, IonIcon } from '@ionic/react';
import useStore from "../modules/userState";
import { useEffect, useRef, useState } from 'react';
import './New.css';
import IObjectKeys from '../modules/IObjectKeys';
import { OverlayEventDetail } from '@ionic/core/components';
import printQueue from '../modules/queue';
import { refreshCircle } from 'ionicons/icons';


const PrintQueue: React.FC = () => {
 
  const [photos, setPhotos]=useState<undefined|IObjectKeys[]>([])
  const [checked, setChecked]=useState<IObjectKeys>({})
  const [checked_plural, setChecked_plural]=useState("")
  const [itemsReady, setItemsReady]=useState(false)
  const [busy, setBusy]=useState(false)
  const {setEmail, setAuthorization, setKey, email, authorization, key}=useStore();
  
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  
  useEffect(()=>{
  //  const getPhotos=async()=>{
 //   }
    getPhotos();
    //setPhotos(result)
    console.log('photos', photos)
  },[]
  ) 

  useIonViewWillEnter(()=> {
    getPhotos();
  })

  const getPhotos=async()=> {
    setPhotos([])
    setBusy(true)
    const result=await printQueue.get(authorization)
    setPhotos(result)
    setBusy(false)
  }

  const toggleCheck=(npn: string) =>{
    const newChecked=checked
    newChecked[npn]=!(newChecked[npn])
    setChecked(newChecked)
    console.log('checked', checked)
    const keycnt=Object.keys(checked).filter(k=>checked[k])
    setItemsReady(keycnt.length>0)
    setChecked_plural((keycnt.length>1)?"s":"")
  }
 
  const clearItems=async ()=> {
    const clearNPNs=Object.keys(checked).filter(k=>checked[k])
    let i=0;
    const promises=[]
    setBusy(true)
    while (i<clearNPNs.length) {
      const npn=clearNPNs[i]
      promises.push(printQueue.completed(authorization, npn))
      i++
    }
    let result=await Promise.all(promises)
    await getPhotos()
    setBusy(false)
    setItemsReady(false)
  }
   
  function doRefresh() {
    setBusy(true)
    getPhotos();
    setBusy(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Print Queue</IonTitle>
          <IonButton slot='end' onClick={()=>{doRefresh()}} >
            <IonIcon slot='icon-only' icon={refreshCircle} ></IonIcon>
          </IonButton>  
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Print Queue</IonTitle>
          </IonToolbar>
        </IonHeader>
        { busy?<div id="pluswrap">
            <div className="plus">
            <IonSpinner></IonSpinner>
            </div>
          </div>:""}
           {photos && photos.map(photo=>(
           <IonItem className='q'>
              <IonCheckbox checked={checked[photo.npn]} className='cb' onClick={()=>{toggleCheck(photo.npn)}}></IonCheckbox>{photo.name} ({photo.npn}) <IonAvatar slot="end">
                          <img src={photo.photo_url}></img>
                        </IonAvatar>
           </IonItem>))}
          { photos?.length==0 && !busy? <IonItem>The Queue is Empty.</IonItem>:""}
      </IonContent>
      <IonFooter><IonButton expand="full" disabled={ !itemsReady} onClick={()=>{clearItems()}}>Clear Item{checked_plural}</IonButton></IonFooter>
    </IonPage>
  );
};

export default PrintQueue
