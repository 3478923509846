import React from 'react';
import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonPage, IonTitle, IonToolbar, useIonAlert, useIonRouter, useIonViewDidEnter, IonRouterContext } from '@ionic/react';
import {  useState, useRef, useEffect, useContext } from 'react';
import useStore from "../modules/userState";
import Auth from '../modules/auth';
import { Redirect } from 'react-router';
import { Preferences } from '@capacitor/preferences';


const LoginAuth: React.FC=()=>{ 
    const [open, setOpen] = useState(true)
    const [dialog,openDialog]=useState();
    const [akey,setAkey]=useState('');
    const [present]=useIonAlert();
    const {setEmail, setAuthorization, setKey, email, authorization, key}=useStore();
    const [inputAccess, setAccess] = useState<string>("");
    const input=React.createRef()
    const router=useIonRouter();
    const ionRouterContext = useContext(IonRouterContext);
        
    useIonViewDidEnter(() => {
      console.log('VIEW DID ENTER')
    })

    const submitAccess=async () => {
        if (inputAccess) {   
          let ky=(await Preferences.get({"key": "auth_key"}))
          const auth_key=ky.value as string
          setAkey(auth_key)
          let result=await Auth.authenticate(email, auth_key, inputAccess)         
          if (result && result.Authorization) {
            console.log('Authorized', result)
            setAuthorization(result.Authorization)
            await Preferences.set({"key":"authorization", "value": result.Authorization})
            ionRouterContext.push("/")
          }
          else {
             present( {"header": "There was a problem",
              "message": "Access Code Did not match", buttons: ['Cancel', { text: 'Ok', handler: (d) => console.log('ok pressed') }]});
          }          
        }
    }
    return (
          <IonPage>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Access Code {akey}</IonTitle>
              </IonToolbar>
            </IonHeader>
            {dialog}
            <IonContent fullscreen className="relative">
            <div className="text-center absolute left-0 right-0 top-1/2 min-w-full flex justify-center items-center">
            <div className="border border-gray-100 rounded-xl shadow-2xl p-8 bg-gradient-to-r from-blue-600 via-blue-900 to-blue-600 text-white max-w-xl">
            <IonLabel className="text-lg font-bold">Access Code</IonLabel>
            <IonInput id="access" className="border bg-white border-gray-500 rounded-xl font-sans text-black" onIonInput={(e)=> { setAccess(e.target.value as string)}}/>
            <IonButton onClick={()=>submitAccess()}>Proceed</IonButton>
            </div></div>
            </IonContent>
          </IonPage>
        );
          
}

export default LoginAuth

