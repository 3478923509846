import { Redirect, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { print, eye, triangle,camera, heartDislike } from 'ionicons/icons';
import { useState } from 'react';
import { useStore} from './modules/userState'
import create from 'zustand';
import NewPhotos from './pages/NewPhotos';
import PrintQueue from './pages/PrintQueue';
import Login from './pages/Login';
import LoginAuth from './pages/LoginAuth';
import Find from './pages/Find'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';
import Declined from './pages/Declined';
;

setupIonicReact();


const App: React.FC = () =>{
  const {authorization} =useStore();  
        if (authorization) {
        return (
        <IonApp>
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet>
                <Route exact path="/" >
                 <Redirect to="/New" />
                </Route>
                <Route exact path="/Login" >
                  <Login/>
                </Route>
                <Route exact path="/New" render= {(props)=>{
                  if (authorization) {
                    return <NewPhotos/>
                  }
                  else {
                    return <Login/>
                  }
                }}>
                </Route>
                <Route exact path="/PrintQueue">
                  <PrintQueue />
                </Route>
                <Route path="/Find">
                  <Find/>
                </Route>
                <Route path="/start">
                  <Login></Login>
                </Route>
                <Route exact path="/Login_Auth" >
                  <LoginAuth/>
                </Route>
                <Route exact path="/Declined" >
                  <Declined/>
                </Route>
              </IonRouterOutlet>       
              <IonTabBar slot="bottom">
                <IonTabButton tab="tab1" href="/New">
                  <IonIcon icon={camera} />
                  <IonLabel class="text-lg font-bold">New</IonLabel>
                </IonTabButton>
                <IonTabButton tab="PrintQueue" href="/PrintQueue">
                  <IonIcon icon={print} />
                  <IonLabel class="text-lg font-bold">Print Queue</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Find" href="/Find">
                  <IonIcon icon={eye} />
                  <IonLabel class="text-lg font-bold">Find</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Declined" href="/Declined">
                  <IonIcon icon={heartDislike} />
                  <IonLabel class="text-lg font-bold">Decline</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs> 
          </IonReactRouter>
        </IonApp>
        );
     }
     else {
      return (
        <IonApp>
          <IonReactRouter>
              <IonRouterOutlet>
                <Switch>
                  <Route exact path="/" >
                    <Redirect to="/Login" />
                  </Route>
                  <Route exact path="/Login" >
                    <Login/>
                  </Route>
                  <Route exact path="/Login_Auth" >
                    <LoginAuth/>
                  </Route>
                  <Route path="*" render={() => <Redirect to="/Login" />} />
                </Switch>
              </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      )
    }
  }

export default App;
