import api from './api';
import IObjectKeys from './IObjectKeys';

export default class printQueue {

    public static HOST_URL=process.env.REACT_APP_API_HOST_URL;

    public static getHeaders(authorization: string): IObjectKeys {
                return { "Authorization": authorization}
    }

    public static async get(authorization: string): Promise<any[]> {
        const headers=this.getHeaders(authorization)
        let result=await api.get(`${this.HOST_URL}print_queue/`,headers, {JSON:true}) as IObjectKeys[]
        return result
    }

    public static async completed(authorization: string, npn: string): Promise<any> {
        const headers=this.getHeaders(authorization)
        let result=await api.post(`${this.HOST_URL}print_complete/`,headers,{"npn": npn})
        return result
    }

    public static async requestPrint( npn: string): Promise<any> {
        return 'blah'
    }


}