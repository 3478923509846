import api from "./api";

export default class Auth {

    public static HOST_URL=process.env.REACT_APP_API_HOST_URL;

    public static async start(email: string): Promise<any> {
        let result=await api.get(`${this.HOST_URL}auth_start/${email}`)
        console.log('result', result)
        return result
    }

    public static async authenticate(email: string, key: string, access_code:string): Promise<any>{
        const result=await api.get(`${this.HOST_URL}authenticate/${email}/${key}/${access_code}`, {},{})
        return result
    }

    public static async validateAuthorization(authorization:string): Promise<any>{
        const result=await api.get(`${this.HOST_URL}auth_validate`,{"Authorization": authorization},{})
        return result;
    }

}