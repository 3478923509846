import create from "zustand";

interface userState {
  email: string;
  key: string;
  authorization: string;
  setEmail: (email:string) =>void;
  setAuthorization: (auth: string) =>void;
  setKey: (ky: string) =>void;
}

export const useStore = create<userState>((set)=> ({
    email: "",
    key: "",
    authorization: "",
    setEmail: (email)=> { set((state)=>( {...state, email}))},
    setAuthorization: (authorization)=> { set((state)=>( {...state, authorization}));},
    setKey: (key)=> { set((state)=>( {...state, key}))},
}))

export default useStore;