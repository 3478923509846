import { IonContent, IonHeader, IonModal, IonCard, IonSpinner, IonToast, IonButtons, IonButton, IonPage, IonTitle, IonToolbar, IonSearchbar, useIonAlert, useIonToast, IonLabel} from '@ionic/react';
import useStore from "../modules/userState";
import { useState } from 'react';
import IObjectKeys from '../modules/IObjectKeys';
import './New.css';
import Bondsman from '../modules/bondsman';
import Photos from '../modules/photos';

const Find: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [inactive, setInactive] = useState(false);
  const [access_code, setAccessCode] = useState('');
  const {setEmail, setAuthorization, setKey, email, authorization, key}=useStore();
  const [present]=useIonAlert();
  const [toast, dismiss] =useIonToast()
  const [showModal, setShowModal] = useState(false);
  const [dialogPhoto, setDialogPhoto] = useState<IObjectKeys>({})
  const [busy, setBusy]=useState(false)

  const agentLookup=async () => {
    let result=await Bondsman.lookup(searchText)
    if (result) {
      setDialogPhoto(result)
      console.log('result', result)
      if (result.licenses) {
         const licenses=result.licenses.filter(lic=>(lic.licenseStatus=="Active"))
         setInactive((licenses.length==0))
      }
      let code=''
      try {
        code=await Bondsman.getAccessCode(result.agent.npn, authorization)
        console.log(code)
      }
      catch (ex) {}
      code=code || '';
      setAccessCode(code)      
      setShowModal(true)
    }
    else {
      present( {"header": `${searchText} not found.`,
      "message": "Unable to find a match for this NPN", buttons: ['Cancel', { text: 'Ok', handler: (d) => setSearchText("") }]});
    }
  }

  const reprint=async() =>{
    setShowModal(false)
    setBusy(true)
    const result=await Photos.requestPrint(authorization,searchText)
    setSearchText('')
    setBusy(false)
    present({
      buttons: [{ text: 'OK', handler: () => dismiss() }],
      message: 'Bondsman Queued for Badge Printing.'
    })

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Find</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Find</IonTitle>
          </IonToolbar>
        </IonHeader>
        { busy?<div id="pluswrap">
            <div className="plus">
            <IonSpinner></IonSpinner>
            </div>
          </div>:""}
        <IonSearchbar value={searchText} placeholder="Bondsman NPN" onKeyUp={e=>{ if (e.keyCode==13){ agentLookup() }}} onIonChange={e => {  setSearchText(e.detail.value!)}}/>
      </IonContent>
      
      { inactive?
          <IonModal isOpen={showModal} >         
          <IonHeader>
          <IonTitle>{dialogPhoto.agent?.name} ({dialogPhoto.agent?.npn})</IonTitle>
          <IonToolbar>              
            <IonButtons slot="start">
              <IonButton onClick={() => setShowModal(false)}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
         <IonContent className="ion-padding">
          <IonCard class="bg-red-500 color-white text-lg font-bold text-white">
            This Agent does not have an active license.
          </IonCard>
        </IonContent>
        </IonModal>  
        :
        <IonModal isOpen={showModal} >         
            <IonHeader>
            <IonTitle>{dialogPhoto.agent?.name} ({dialogPhoto.agent?.npn})</IonTitle>
            <IonToolbar>              
              <IonButtons slot="start">
                <IonButton onClick={() => setShowModal(false)}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => reprint()}>
                  Reprint
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
           <IonContent className="ion-padding">
            <IonCard>
              <img className="agt_photo" src={dialogPhoto?.photo_url}/>
            </IonCard>
            <IonLabel class="text-xl font-bold p-5">{access_code}</IonLabel>
          </IonContent>
          </IonModal>  
        }    
    </IonPage>
  );
};

export default Find;
