import { useStore } from './userState'
import api from './api';
import IObjectKeys from './IObjectKeys';
import Bondsman from './bondsman';

export default class Photos {

    public static HOST_URL=process.env.REACT_APP_API_HOST_URL;

    public static getHeaders(authorization: string): IObjectKeys {
                return { "Authorization": authorization}
    }

    public static async getNew(authorization:string ): Promise<any[]> {
        const headers=this.getHeaders(authorization)
        let result=await api.get(`${this.HOST_URL}photo_new/`,headers, {JSON:true}) as IObjectKeys[]
        return result
    }

    public static async approve(photo_id: string, npn: string, authorization: string): Promise<any> {
        const headers=this.getHeaders(authorization)
        let result=await api.post(`${this.HOST_URL}photo_approve/`,headers, {"id": photo_id, "object_id": npn})
        return result
    }

    public static async decline(photo_id: string, npn: string, reason:string, authorization: string): Promise<any> {
        const headers=this.getHeaders(authorization)
        let result=await api.post(`${this.HOST_URL}photo_decline/`,headers, {"id": photo_id, "object_id": npn, "decline_reason": reason})
        return result
    }

    public static async requestPrint(authorization: string, npn: string) {
        const headers=this.getHeaders(authorization)
        let result=await api.post(`${this.HOST_URL}print_request/`,headers, {"npn": npn})
        return result
    }


    public static async getAgentPhoto(authorization: string, npn: string, id:string) : Promise<IObjectKeys|undefined> {
        const headers=this.getHeaders(authorization)
        let result: IObjectKeys[]= (await api.get(`${this.HOST_URL}photos_get/${npn}?id=${id}`,headers, {JSON:true})) as IObjectKeys[]
        return result.length>0?result[0]:undefined
    }

    public static async getAllForAgent(authorization: string, npn: string) : Promise<any[]> {
        const headers=this.getHeaders(authorization)
        let result: IObjectKeys[]= (await api.get(`${this.HOST_URL}photos_get/${npn}`,headers, {JSON:true})) as IObjectKeys[]
        return result
    }




    public static async getDeclined(authorization: string) : Promise<IObjectKeys[]> {
        const headers=this.getHeaders(authorization)
        let results: IObjectKeys[]|undefined= await api.get(`${this.HOST_URL}declined`,headers, {JSON:true}) as IObjectKeys[]
        if (results) {
            let i=0;
            while (i<results.length) {
                let dec=results[i]
                let photo=await this.getAgentPhoto(authorization,dec.PartitionKey, dec.photo_id)           
                if (photo && photo.link) {
                    dec.link=photo.link
                }
                results[i]=dec
                i++
            }
            return results;        
        }
        else return [] as IObjectKeys[]
    }
}

