import React from 'react';
import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonNav, IonPage, IonTitle, IonToolbar, IonRouterContext, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import useStore from "../modules/userState";
import { useState } from 'react';
import Auth from '../modules/auth';
import { useIonRouter } from '@ionic/react';
import { Preferences } from '@capacitor/preferences'
import { useContext } from 'react';

const Login: React.FC=()=>{ 



    const {setEmail, setAuthorization, setKey}=useStore();
    const [inputEmail, setInput] = useState<string|undefined|null|number>("");
    const [fieldLbl, setLabel]=useState("Email Address")
    const router=useIonRouter();
    const ionRouterContext = useContext(IonRouterContext);
    let lbl="Email Address"



    const submitEmail=async () => {
        let self=this;
        if (inputEmail) {
          let result=await Auth.start(inputEmail.toString())
          if (result && result.key) {
            setKey(result.key)
            await Preferences.set({"key": "auth_key", "value": result.key})
            setEmail(inputEmail.toString())
          }
          router.push('/Login_Auth')
        }
    }

    useIonViewWillEnter(async ()=> {
       const {value}=await Preferences.get({"key":"authorization"})
       console.log('VIEW WILL ENTER')
       if (value) {
        let result
        result=await Auth.validateAuthorization(value)
        console.log('result', result)
        if (!result) {
           await Preferences.remove({"key": "authorization"})
           console.log('authcheck failed')
        }
        else {
          console.log("Authorized!")
          setAuthorization(value)
          ionRouterContext.push("/")
        }
       }
    })

    useIonViewDidEnter(() => {
      console.log('VIEW DID ENTER')
    })
    setTimeout(()=>{
      document.getElementById("email")?.focus()
    },1000)
   

    return (
          <IonPage>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Logon</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="relative">
            <div className="text-center absolute left-0 right-0 top-1/2 min-w-full flex justify-center items-center">
            <div className="border border-gray-100 rounded-xl shadow-2xl p-8 bg-gradient-to-r from-blue-600 via-blue-900 to-blue-600 text-white max-w-xl">
            <IonLabel className="text-lg font-bold">{fieldLbl}</IonLabel>
            <IonInput className="bg-white border border-gray-500 rounded-xl text-black focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="email" onIonChange={(e)=> { setInput(e.target.value)}}/>
            <IonButton onClick={()=>submitEmail()}>Proceed <IonIcon name='arrow-left'></IonIcon></IonButton>
            </div></div>
            </IonContent>
          </IonPage>
        );
          
}

export default Login

