import { IonAvatar,IonButton,IonButtons, IonContent, IonHeader, IonItem, IonList, IonLabel, IonInput, IonModal, IonPage,IonProgressBar, IonTitle, IonToolbar, useIonViewWillEnter, IonCard, IonSpinner, IonIcon, IonSlides, IonSlide, IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import useStore from "../modules/userState";
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import Photos from '../modules/photos';
import './New.css';
import IObjectKeys from '../modules/IObjectKeys';
import { OverlayEventDetail } from '@ionic/core/components';
import { refresh, refreshCircle } from 'ionicons/icons';
import Bondsman from '../modules/bondsman';


const NewPhotos: React.FC = () => {
 
  const [photos, setPhotos]=useState<IObjectKeys[]>([] as IObjectKeys[])
  const [priorPhotos, setpriorPhotos]=useState<undefined|IObjectKeys[]>([] as IObjectKeys[])
  const [priorPhotosCnt, setpriorPhotoCnt]=useState(0)
  const {setEmail, setAuthorization, setKey, email, authorization, key}=useStore();

  const modal = useRef<HTMLIonModalElement>(null);
  const modal_decline = useRef<HTMLIonModalElement>(null);
  const modal_prior=useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalDecline, setShowModalDecline] = useState(false);
  const [showModalPriorPhotos, setShowModalPriorPhotos] = useState(false);
  const [showBusy, setBusy] = useState(false);
  const [dialogPhoto, setDialogPhoto] = useState<IObjectKeys>({})
  const [message, setMessage] = useState(
    'This modal example uses triggers to automatically open a modal when the button is clicked.'
  );
  
  useEffect(()=>{

    getPhotos();
    //setPhotos(result)
    console.log('photos', photos)
  },[]
  ) 

  const getPhotos=async()=>{
    setPhotos([])
    setBusy(true)
    const result=await Photos.getNew(authorization);
    let i=0;
    while (i<result.length) {
      result[i].npn=result[i].object_id
      i++;
    }
    setPhotos(result)
    i=0;
    while (i<result.length) {
      try {
        let agt=await Bondsman.getAgentInfo(result[i].npn)
        result[i].name=agt.name
        /*console.log('agt ', agt.npn, agt.name)
        if (agt && agt.npn) {
          setPhotos (photos.map((photo)=> 
            photo.npn==agt.npn? {...photo, name: agt.name} : {...photo }
          )         )  
        } */    
      }
      catch(ex) {}
      i++;
    }
    console.log('phots', result)
    setPhotos(result)
    setBusy(false)
    //return result   
   //return []   
  }

  const getPriorPhotos=async(npn: string)=>{
    setpriorPhotos([])
    setBusy(true)
    console.log('get photos click.')
    const result=await Photos.getAllForAgent(authorization,npn)
    let prior=result.filter(p=>{ return (p.STATUS=="inactive")})
    let agt=await Bondsman.getAgentInfo(npn, true)
    if (agt.photo_url) {
      let current_photo={"link":agt.photo_url}
      prior=[current_photo, ...prior]
    }
    setpriorPhotoCnt(prior?.length)
    setpriorPhotos(prior)
    setBusy(false)
  }

  function openPriorPhotos() {
    setShowModalPriorPhotos(true)
  }


  function openPhoto(entry: IObjectKeys) {
    setShowModal(true)
    setDialogPhoto(entry)
    getPriorPhotos(entry.object_id)
  }
 
  async function doRefresh() {
      setBusy(true)
      await getPhotos();
      setBusy(false);
  }


  function approve() {
    const sendApproval=async()=> {
      if (dialogPhoto) {
        const result=await Photos.approve(dialogPhoto["id"], dialogPhoto.object_id, authorization)
      }
    }
    setBusy(true)
    sendApproval();
    setShowModal(false)
    setTimeout(()=>{getPhotos(); setBusy(false)},1000)
  }

  function declineOpen()  {
    setShowModal(false)
    setShowModalDecline(true)
  }

  async function decline() {
    const reason=input.current?.value!.toString()
    console.log('Decline reason received',input.current?.value)
    const result=await Photos.decline(dialogPhoto["id"], dialogPhoto.object_id, reason!,authorization)
    await getPhotos()
    setShowModalDecline(false)
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === 'confirm') {
      setMessage(`Hello, ${ev.detail.data}!`);
    }
  }

  function enlargePhoto(ev :IObjectKeys) {
    console.log('ev',ev.target)
    ev.target.setAttribute("class","enlarged_prior rounded-md")
    setTimeout(()=>{ev.target.setAttribute("class","agt_prior_photo rounded-md")},4000)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>New Photos</IonTitle>
          <IonButton slot='end' onClick={()=>{doRefresh()}} >
            <IonIcon slot='icon-only' icon={refreshCircle} ></IonIcon>
          </IonButton>          
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">New</IonTitle>
          </IonToolbar>
        </IonHeader>
        { showBusy?<div id="pluswrap">
            <div className="plus">
            <IonSpinner></IonSpinner>
            </div>
          </div>:""}   
        <IonList>
           {photos && photos.map(photo=>( <IonItem key={photo.id} onClick={() =>{ openPhoto(photo)}}>
            <IonAvatar slot="start">
              <img src={photo.link}></img>
            </IonAvatar>             
            {photo.name} ({photo.npn})</IonItem>))}
        </IonList>        
      </IonContent>
      <IonModal ref={modal} isOpen={showModal} onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Approve Photo?</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowModal(false)}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => approve()}>
                  Approve
                </IonButton>
                <IonButton strong={true} onClick={() => declineOpen()}>
                  Decline
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <IonCard >
                      <img className="agt_photo rounded-lg" src={dialogPhoto?.link}/>
                    </IonCard>  
                  </IonCol> 
                  <IonCol size="6">
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>Prior Photo{priorPhotosCnt>1?"s":""}</IonCardTitle>
                      </IonCardHeader>
                      {priorPhotos  && priorPhotos.map(photo => (
                        <img src={photo.link} className="agt_prior_photo rounded-md"  onClick={(ev) => enlargePhoto(ev)}></img>
                      ))
                      }
                    </IonCard>
                  </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          { showBusy?<IonProgressBar type="indeterminate"></IonProgressBar>:""}       </IonModal>           
        <IonModal ref={modal_decline} isOpen={showModalDecline} onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader>
          
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowModalDecline(false)}>Cancel</IonButton>
              </IonButtons>
             <IonTitle>Photo Decline</IonTitle>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => decline()}>
                  Decline
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonItem>
              <IonLabel position="stacked">Enter Reason Photo is Declined</IonLabel>
              <IonInput ref={input} type="text" placeholder="Decline Reason" />
            </IonItem>
          </IonContent>
        </IonModal>        
    </IonPage>
  );
};

export default NewPhotos;
